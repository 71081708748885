import { Button } from "@mui/material";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link, useParams } from "react-router-dom";
import api from "../../api";
import ImageView from "../../components/client/image";
import LogsView from "../../components/client/logs";
import CosButton from "../../components/CosButton";
import LabelInfo from "../../components/label-info";
import { Client } from "../../types/client";
import tasks from "../../utils/tasks";

const VerCliente = () => {
  const { id } = useParams();
  const [client, setClient] = useState<Client>();

  useEffect(() => {
    loadCliente(id);
  }, []);

  const loadCliente = (id: any) => {
    api.get(
      {
        path: `/api/cliente/info?id=${id}`,
      },
      (data) => {
        setClient(data);
      },
      (errors) => {
        console.log(errors);
      },
    );
  };

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px] pb-[40px]">
        <div className="flex flex-row px-[100px] py-[20px] w-full justify-between">
          <span className=" text-[20px] font-bold text-center tablet:text-left">
            Informação do Entrevistado (Cliente Adrmm)
          </span>
          <Link to={`/gerais/actualizar-cliente/${id}`}>
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: "#4F8F56" }}
            >
              ACTUALIZAR
            </Button>
          </Link>
        </div>
        <div className="flex flex-col gap-[15px] px-[30px] tablet:flex-row tablet:px-[80px] tablet:gap-[50px]">
          <div className="flex flex-1 gap-[15px] flex-col ">
            <span className=" text-[14px] font-bold text-blue-900">
              Informações Pessoais
            </span>
            <LabelInfo
              title="Nome Completo"
              value={tasks.verificarValor(client?.nome, "Sem informação")}
            />
            <LabelInfo
              title="Email"
              value={tasks.verificarValor(client?.email, "Sem informação")}
            />
            <LabelInfo
              title="Numero de telefone 1"
              value={tasks.verificarValor(client?.contacto1, "Sem informação")}
            />
            <LabelInfo
              title="Numero de telefone 2"
              value={tasks.verificarValor(client?.contacto2, "Sem informação")}
            />
            <LabelInfo
              title="Numero de telefone 3"
              value={tasks.verificarValor(client?.contacto3, "Sem informação")}
            />
            <LabelInfo
              title="Numero de telefone 4"
              value={tasks.verificarValor(client?.contacto4, "Sem informação")}
            />
            <LabelInfo
              title="Tipo de Actividade"
              value={tasks.verificarValor(
                client?.tipo_actividade,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Área Operacional"
              value={tasks.verificarValor(
                client?.area_operacional,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Bairro"
              value={tasks.verificarValor(client?.bairro, "Sem informação")}
            />
            <LabelInfo
              title="Avenida/Rua"
              value={tasks.verificarValor(
                client?.avenida_rua,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="N.da porta/Casa"
              value={tasks.verificarValor(client?.n_casa_rua, "Sem informação")}
            />
            <LabelInfo
              title="Andar"
              value={tasks.verificarValor(client?.andar, "Sem informação")}
            />
            <LabelInfo
              title="Flat"
              value={tasks.verificarValor(client?.flat, "Sem informação")}
            />
            <LabelInfo
              title="Numero de Agregado Familiar"
              value={tasks.verificarValor(
                client?.numero_de_agregado,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Casa Própria"
              value={tasks.verificarValor(
                client?.casa_propria,
                "Sem informação",
              )}
            />
          </div>
          <div className="flex flex-1 gap-[15px] flex-col">
            <span className=" text-[14px] font-bold text-blue-900">
              Dados do Serviço
            </span>
            <LabelInfo
              title="Tem recebido factura de água?"
              value={tasks.verificarValor(
                client?.tem_recebido_factura,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Água sai com regularidade?"
              value={tasks.verificarValor(
                client?.agua_sai_regularidade,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Em que estado se encontra o seu cavalete?"
              value={tasks.verificarValor(
                client?.estado_cavalete,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="O cavalete tem fugas?"
              value={tasks.verificarValor(
                client?.cavalete_tem_fugas,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="qual é o estado do seu contador?"
              value={tasks.verificarValor(
                client?.estado_do_contador,
                "Sem informação",
              )}
            />

            <LabelInfo
              title="Qual é o diâmetro do contador?"
              value={tasks.verificarValor(
                client?.diametro_contador,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="A leitura tem sido feita com regularidade?"
              value={tasks.verificarValor(
                client?.regularidade_leitura,
                "Sem informação",
              )}
            />

            <LabelInfo
              title="Qual é o nivél de satisfação pelos serviços da ADRMM?"
              value={tasks.verificarValor(
                client?.avaliacao_adrmm,
                "Sem informação",
              )}
            />

            <LabelInfo
              title="Numero do contador"
              value={tasks.verificarValor(
                client?.numero_contador,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Leitura Actual?"
              value={tasks.verificarValor(
                client?.leitura_actual,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Tem suspeita de consumo ilegal?"
              value={tasks.verificarValor(
                client?.suspeita_ilegal,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Observações"
              value={tasks.verificarValor(client?.observacao, "Sem informação")}
            />
          </div>
        </div>
        <div className="w-full  py-[20px] px-[30px] tablet:px-[80px]">
          <div className="py-[20px]">
            <span className=" text-[14px] font-bold text-blue-900">Fotos</span>
          </div>
          <div className="grid grid-cols-1 gap-[15px] md:grid-cols-3">
            <div className="flex flex-1">
              <ImageView name={client?.foto_contador} />
            </div>
            <div>
              <ImageView name={client?.foto_residencia} />
            </div>
            <div>
              <ImageView name={client?.foto_balisa} />
            </div>
          </div>
        </div>
        <div className="w-full  py-[20px] px-[30px] tablet:px-[80px]">
          <div className="flex flex-col py-[20px]">
            <span className=" text-[14px] font-bold text-blue-900">
              Geolocalização
            </span>
            <div>
              <Link
                to={`https://www.google.com/maps?q=${client?.latitude},${client?.longitude}&t=m`}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `https://www.google.com/maps?q=${client?.latitude},${client?.longitude}&t=m`,
                    "_blank",
                  );
                }}
              >
                <CosButton>DIREÇÕES NO GOOGLE MAP</CosButton>
              </Link>
            </div>
          </div>

          <div className="w-full h-[400px]">
            <APIProvider
              apiKey={"AIzaSyB5v8QIe5AcXYGeGwZuGUcnZh_21ZyBHxM"}
              onLoad={() => console.log("Maps API has loaded.")}
            >
              <Map
                style={{ width: "100%", height: 400 }}
                defaultZoom={14}
                defaultCenter={{
                  lat: Number(client?.latitude),
                  lng: Number(client?.longitude),
                }}
              >
                <Marker
                  position={{
                    lat: Number(client?.latitude),
                    lng: Number(client?.longitude),
                  }}
                />
              </Map>
            </APIProvider>
            <div className="w-full flex flex-row border-[2px] mt-[15px] border-black-300 px-[6px] items-center rounded-[8px] py-[5px]">
              <div className="flex flex-1 justify-evenly mr-[20px]">
                <span className=" font-bold text-[15px]">
                  Lat : <span className=" font-normal">{client?.latitude}</span>{" "}
                </span>
                |
                <span className=" font-bold">
                  Lng :{" "}
                  <span className=" font-normal">{client?.longitude}</span>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[50px]">
          <LogsView id={Number(id)} />
        </div>
        {/* <div className="w-full  py-[20px] px-[30px] tablet:px-[80px]">
          <Link to={""}>
            <CosButton fullWidth>ACTUALIZAR</CosButton>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default VerCliente;
