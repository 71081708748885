import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";
import { BASE_URL } from "../../api/setup";
interface ImageViewProps {
  name: string;
}

const ImageView = ({ name }: ImageViewProps) => {
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();

    return (
      <div className="tool flex flex-row absolute z-40 left-[10px] top-[5px] gap-[20px]">
        <button onClick={() => zoomIn()}>
          <div className="flex w-[40px] h-[40px] bg-blue-900 justify-center items-center rounded-[10px]">
            <span className="font-extrabold text-[23px] text-[#fff]">+</span>
          </div>
        </button>
        <button onClick={() => zoomOut()}>
          <div className="flex w-[40px] h-[40px] bg-blue-900 justify-center items-center rounded-[10px]">
            <span className="font-extrabold text-[23px] text-[#fff]">-</span>
          </div>
        </button>
        {/* <button onClick={() => resetTransform()}>x</button> */}
      </div>
    );
  };

  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <div className=" relative">
          <Controls />
          <TransformComponent>
            <img
              className="w-[500px] h-auto bg-black-700"
              src={`${BASE_URL}/api/resources/foto/${name}`}
            />
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  );
};

export default ImageView;
