import { useEffect, useState } from "react";

interface ViewLogProps {
  show: boolean;
  type?: "SUCCESS" | "WARNING" | "ERROR";
  message?: string;
  time?: number;
}

export type { ViewLogProps };

const useViewLog = () => {
  const [viewLog, setViewLog] = useState<ViewLogProps>({
    show: false,
  });

  const [timeShow, setTimeShow] = useState<number>(2000);

  useEffect(() => {
    if (viewLog.show) {
      setTimeout(() => {
        setTimeShow(2000);
        setViewLog({
          ...viewLog,
          show: false,
        });
      }, timeShow);
    }
  }, [viewLog]);

  const setNewLog = (viewLog: Omit<ViewLogProps, "show">) => {
    setTimeShow(viewLog.time ?? 2000);
    setViewLog({
      show: true,
      ...viewLog,
    });
  };

  return {
    viewLog,
    setNewLog,
  };
};

export default useViewLog;
