import { useEffect, useState } from "react";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import { useBetterState } from "../../hooks/use-better-state";
import useViewLog from "../../hooks/use-view-log";
import { Client } from "../../types/client";
import { DataPagened } from "../../types/utils";
import tasks from "../../utils/tasks";

type NavFilter =
  | "TODOS"
  | "AREAO"
  | "BAIRRO"
  | "BRIGADISTAS"
  | "REGISTO_SUSPEITA"
  | "CLIENTE"
  | "INTERVALOS";
interface DropdownNavState {
  regiao: string;
  situacao: string;
}

const defaultState = {
  data: [],
  pagination: {
    currentPage: 1,
    totalPages: 1,
  },
};

export type { DropdownNavState, NavFilter };

const MapaGeralViewModel = () => {
  const pageq = 1000;

  const [email, setEmail] = useState("");
  const currentView = useBetterState<NavFilter>("TODOS");

  const dropdownNavState = useBetterState<DropdownNavState>({
    regiao: "",
    situacao: "none",
  });

  const { viewLog, setNewLog } = useViewLog();
  const [clientes, setClientes] = useState<DataPagened<Client>>(defaultState);

  const handleClick = () => {
    if (currentView.value === "AREAO") {
      loadAreaClient(
        dropdownNavState.value.regiao,
        dropdownNavState?.value.situacao,
        1,
      );
    } else if (currentView.value === "BAIRRO") {
      loadBairroClient(
        dropdownNavState.value.regiao,
        dropdownNavState?.value.situacao,
        1,
      );
    } else if (currentView.value === "CLIENTE") {
      if (clienteState.value.value.trim() === "") {
        setNewLog({
          type: "WARNING",
          message: "Prencha o campo de filtro.",
        });
      } else {
        loadClientFiltro(clienteState.value.type, clienteState.value.value);
      }
    } else if (currentView.value === "REGISTO_SUSPEITA") {
      loadRegistoSuspeita(
        dropdownNavState.value.regiao,
        dropdownNavState?.value.situacao,
        1,
      );
    } else if (currentView.value === "INTERVALOS") {
      loadIntervalos(
        dropdownNavState.value.regiao,
        dropdownNavState.value.situacao,
        1,
      );
    } else {
      loadBrigadistaClientes(
        dropdownNavState.value.regiao,
        dropdownNavState.value.situacao,
        1,
      );
    }
  };

  const listenerNav = (
    state: NavFilter,
    dropdownNav: DropdownNavState,
    email: string,
  ) => {
    setClientes(defaultState);
    currentView.value = state;
    if (state === "TODOS") {
      loadClientes(1);
    } else if (state === "AREAO") {
      dropdownNavState.value = {
        regiao: dropdownNav.regiao,
        situacao: dropdownNav.situacao,
      };
    } else if (state === "BAIRRO") {
      dropdownNavState.value = {
        regiao: dropdownNav.regiao,
        situacao: dropdownNav.situacao,
      };
    } else if (state === "REGISTO_SUSPEITA") {
      dropdownNavState.value = {
        regiao: dropdownNav.regiao,
        situacao: dropdownNav.situacao,
      };
    } else if (state === "BRIGADISTAS") {
      dropdownNavState.value = {
        regiao: dropdownNav.regiao,
        situacao: dropdownNav.situacao,
      };
    } else if (state === "INTERVALOS") {
      dropdownNavState.value = {
        regiao: dropdownNav.regiao,
        situacao: dropdownNav.situacao,
      };
    } else {
      setEmail(email);
    }
  };

  useEffect(() => {
    loadClientes(1);
  }, []);

  const loadIntervalos = (inicio: string, fim: string, page: number) => {
    api.get(
      {
        path: "/api/cliente/intervalos",
        params: {
          inicio: inicio,
          fim: fim,
          page: page,
          ...(Number.isInteger(Number(pageq)) ? { pageq: pageq } : {}),
        },
      },
      (data) => {
        setClientes(data);
        if (page === 1) {
          setNewLog({
            type: "SUCCESS",
            message: `Foram encontrados ${data.total} entrevistados.`,
          });
        }
      },
      (error) => {
        setNewLog({
          type: "WARNING",
          message: "Erro ao ler os dados, verifique sua conexão de internet.",
        });
      },
    );
  };

  const loadBairroClient = (bairro: string, situacao: string, page: number) => {
    const params = {
      ...(bairro === "" ? {} : { bairro: bairro }),
      ...(situacao === "" ? {} : { situacao: situacao }),
      page: page,
      ...(Number.isInteger(Number(pageq)) ? { pageq: pageq } : {}),
    };

    api.get(
      {
        path: "/api/cliente/maps/bairro-situacao",
        params: params,
      },
      (data) => {
        setClientes(data);
        if (page === 1) {
          setNewLog({
            type: "SUCCESS",
            message: `Foram encontrados ${data.total} entrevistados.`,
          });
        }
      },
      (error) => {
        setNewLog({
          type: "WARNING",
          message: "Erro ao ler os dados, verifique sua conexão de internet.",
        });
      },
    );
  };

  const loadRegistoSuspeita = (
    registo: string,
    suspeita: string,
    page: number,
  ) => {
    const params = {
      ...(registo === "" ? {} : { registo: registo }),
      ...(suspeita === "" ? {} : { suspeita: suspeita }),
      page: page,
      ...(Number.isInteger(Number(pageq)) ? { pageq: pageq } : {}),
    };
    api.get(
      {
        path: "/api/cliente/maps/registo-suspeita",
        params: params,
      },
      (data) => {
        setClientes(data);
        if (page === 1) {
          setNewLog({
            type: "SUCCESS",
            message: `Foram encontrados ${data.total} entrevistados.`,
          });
        }
      },
      (error) => {
        setNewLog({
          type: "WARNING",
          message: "Erro ao ler os dados, verifique sua conexão de internet.",
        });
      },
    );
  };

  const loadAreaClient = (area: string, situacao: string, page: number) => {
    const params = {
      ...(area === "" ? {} : { area: area }),
      ...(situacao === "" ? {} : { situacao: situacao }),
      page: page,
      ...(Number.isInteger(Number(pageq)) ? { pageq: pageq } : {}),
    };
    setClientes(defaultState);
    api.get(
      {
        path: "/api/cliente/maps/area-situacao",
        params: params,
      },
      (data) => {
        if (page === 1) {
          setNewLog({
            type: "SUCCESS",
            message: `Foram encontrados ${data.total} entrevistados.`,
          });
        }
        setClientes(data);
      },
      (error) => {
        setNewLog({
          type: "WARNING",
          message: "Erro ao ler os dados, verifique sua conexão de internet.",
        });
      },
    );
  };

  const loadBrigadistaClientes = (
    email: string,
    data_registo: string,
    page: number,
  ) => {
    const dateB = new Date(data_registo);
    const params = {
      ...(email === "" ? {} : { email: email }),
      ...(data_registo === ""
        ? {}
        : {
            data_registo: `${dateB.getFullYear()}-${String(dateB.getMonth() + 1).padStart(2, "0")}-${String(dateB.getDate()).padStart(2, "0")}`,
          }),
      page: page,
      ...(Number.isInteger(Number(pageq)) ? { pageq: pageq } : {}),
    };
    console.log(params);
    api.get(
      {
        path: "/api/cliente/maps/brigadista-data",
        params: params,
      },
      (data) => {
        if (page === 1) {
          setNewLog({
            type: "SUCCESS",
            message: `Foram encontrados ${data.total} entrevistados.`,
          });
        }
        setClientes(data);
      },
      (error) => {
        setNewLog({
          type: "WARNING",
          message: "Erro ao ler os dados, verifique sua conexão de internet.",
        });
      },
    );
  };

  const loadClientes = (page: number) => {
    api.get(
      {
        path: "/api/cliente/maps",
        params: {
          page: page,
          type: "BRI",
          ...(Number.isInteger(Number(pageq)) ? { pageq: pageq } : {}),
        },
      },
      (data) => {
        if (page === 1) {
          setNewLog({
            type: "SUCCESS",
            message: `Foram encontrados ${data.total} entrevistados.`,
          });
        }
        const datatyped = data;
        setClientes(datatyped);
      },
      (errors) => {
        setClientes({
          ...clientes,
          info: {
            status: "SUCCESS",
            message: "Erro de rede tente novamente.",
          },
        });
      },
    );
  };

  const clienteState = useBetterState({
    type: "CC",
    value: "",
  });

  const handleClienteState = (type: string, value: string) => {
    clienteState.value = {
      type: type,
      value: value,
    };
    console.log(type, value);
  };

  const loadClientFiltro = (type: string, value: string) => {
    setClientes(defaultState);
    api.get(
      {
        path: "/api/cliente/form",
        params: {
          type: type,
          key: value,
        },
      },
      (data) => {
        const client: any[] = data;
        if (client.length === 0) {
          setNewLog({
            type: "WARNING",
            message:
              "Não foi possivél encontrar o cliente, tente outro filtro.",
          });
        } else {
          const clientFound = data[0] as Client;
          if (
            tasks.verificarValor(clientFound.latitude, "null") === "null" ||
            tasks.verificarValor(clientFound.longitude, "null") === "null"
          ) {
            setNewLog({
              type: "WARNING",
              message:
                "Cliente encontrado, mas não possui os dados da geolocalização.",
              time: 5000,
            });
          }
          setClientes({
            ...clientes,
            data: data,
          });
        }
      },
      (errors) => {
        if (errors.status == HTTP_STATUS.BAD_REQUEST) {
          setNewLog({
            type: "WARNING",
            message: "Não foi encontrado cliente com essa especificação.",
          });
        } else if (errors.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão, tente novamente.",
          });
        } else {
          setNewLog({
            type: "ERROR",
            message: "Erro inesperado, se persistir contacte o administrador.",
          });
        }
      },
    );
  };

  const loadPage = (page: number) => {
    if (currentView.value === "TODOS") {
      loadClientes(page);
    } else if (currentView.value === "AREAO") {
      loadAreaClient(
        dropdownNavState.value.regiao,
        dropdownNavState.value.situacao,
        page,
      );
    } else if (currentView.value === "BAIRRO") {
      loadBairroClient(
        dropdownNavState.value.regiao,
        dropdownNavState.value.situacao,
        page,
      );
    } else if (currentView.value === "REGISTO_SUSPEITA") {
      loadRegistoSuspeita(
        dropdownNavState.value.regiao,
        dropdownNavState.value.situacao,
        page,
      );
    } else if (currentView.value === "INTERVALOS") {
      loadIntervalos(
        dropdownNavState.value.regiao,
        dropdownNavState.value.situacao,
        page,
      );
    } else {
      loadBrigadistaClientes(
        dropdownNavState.value.regiao,
        dropdownNavState.value.situacao,
        page,
      );
    }
  };

  return {
    viewLog,
    clientes,
    setClientes,
    loadClientes,
    currentView,
    email,
    pageq,
    listenerNav,
    loadPage,
    loadBrigadistaClientes,
    dropdownNavState,
    handleClick,
    handleClienteState,
    clienteState,
  };
};

export default MapaGeralViewModel;
