import { Alert } from "@mui/material";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import CosButton from "../../components/CosButton";
import CosTextField from "../../components/CosTextFiel";
import schemas from "../../utils/schemas";
import SignInViewModel from "../../viewmodel/authentication/sign-in";

const SignIn = () => {
  const { isLoading, viewLog, submitLoginForm } = SignInViewModel();

  return (
    <div className="flex flex-col gap-[15px]">
      <Formik
        initialValues={{
          email: "",
          senha: "",
        }}
        validationSchema={schemas.authentication}
        onSubmit={(values) => {
          console.log("onsubmit", values);
          submitLoginForm(values.email, values.senha);
        }}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => {
          return (
            <div className="flex flex-1 flex-col gap-[15px]">
              <div className="flex flex-col">
                <p className="text-[19px] font-bold text-center tablet:text-left">
                  Insira as suas credênciais
                </p>
              </div>

              <CosTextField
                id="outlined-basic"
                size="small"
                style={{ width: 300, maxWidth: 300 }}
                label="Email"
                value={values.email}
                variant="outlined"
                field="email"
                errorMessage={errors.email}
                setFieldValue={setFieldValue}
              />
              <CosTextField
                type="password"
                id="outlined-basic"
                size="small"
                label="Senha"
                errorMessage={errors.senha}
                value={values.senha}
                variant="outlined"
                field="senha"
                autoComplete="off"
                setFieldValue={setFieldValue}
              />
              {viewLog.show && (
                <div className="w-[300px]">
                  <Alert severity="warning">{viewLog.message}</Alert>
                </div>
              )}
              {false && (
                <div>
                  <Link to={"/authentication/forgot-email"}>
                    <span className=" text-blue-900 font-bold text-[14px]">
                      Esqueceu senha?
                    </span>
                  </Link>
                </div>
              )}
              <CosButton
                isLoading={isLoading.value}
                onClick={() => {
                  if (Object.values(errors).length > 0) {
                    return;
                  }
                  handleSubmit();
                }}
              >
                ENTRAR
              </CosButton>
              <Link
                to={
                  "http://cadastro.adrmm.co.mz:3000/api/resources/foto/adrmm.apk"
                }
              >
                <CosButton fullWidth style={{ backgroundColor: "#ed1c30" }}>
                  Baixar a Versão Mobile
                </CosButton>
              </Link>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default SignIn;
