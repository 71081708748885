function convertToGMTPlus2(dateString: string): string {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Calculate the GMT+2:00 offset in milliseconds
  const offset = 2 * 60 * 60 * 1000;

  // Apply the offset to the UTC time
  const gmtPlus2Date = new Date(date.getTime() + offset);

  // Format the date as DD-MM-YYYY HH:mm
  const day = String(gmtPlus2Date.getUTCDate()).padStart(2, "0");
  const month = String(gmtPlus2Date.getUTCMonth() + 1).padStart(2, "0");
  const year = gmtPlus2Date.getUTCFullYear();
  const hours = String(gmtPlus2Date.getUTCHours()).padStart(2, "0");
  const minutes = String(gmtPlus2Date.getUTCMinutes()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

function verificarValor(valor: any, textoPersonalizado: string): string {
  if (
    valor === null ||
    valor === undefined ||
    valor === "" ||
    valor === "null"
  ) {
    return textoPersonalizado;
  }
  return valor;
}

const tasks = {
  convertToGMTPlus2,
  verificarValor,
};

export default tasks;
