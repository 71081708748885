import { useEffect, useState } from "react";
import api from "../api";
import { useBetterState } from "../hooks/use-better-state";

interface EstadoContador {
  Bom: number;
  Sem: number;
  Avariado: number;
  NaoV: number;
  null: number;
  total: number;
}

interface Situacao {
  Correcto: number;
  Incompleto: number;
  Irregular: number;
  null: number;
  total: number;
}

interface BrigadistaDese {
  id: number;
  nome: string;
  telefone: string;
  email: string;
  role: string;
  total_clientes: number;
}

export type { BrigadistaDese };

const HomeViewModel = () => {
  const estatisticas = useBetterState<any[]>([]);

  const [estado, setEstado] = useState<EstadoContador>({
    Bom: 0,
    Sem: 0,
    Avariado: 0,
    NaoV: 0,
    null: 0,
    total: 0,
  });

  const [situacao, setSituacao] = useState<Situacao>({
    Correcto: 0,
    Incompleto: 0,
    Irregular: 0,
    null: 0,
    total: 0,
  });

  const [brigadistaDese, setBrigadistasDese] = useState<BrigadistaDese[]>([]);

  useEffect(() => {
    loadEstado();
    loadSituacao();
    loadEstatisticas();
    loadDesempenhoBrigadistas();
  }, []);

  const criarAreaNaoDefinida = (dados: any[]): any[] => {
    // Variáveis para somar as quantidades das áreas operacionais null e ""
    let somaNaoDefinida = 0;

    // Novo array que vai armazenar os dados sem as áreas null e ""
    const dadosFiltrados = dados.filter((item) => {
      if (item.area_operacional === "" || item.area_operacional === null) {
        somaNaoDefinida += Number(item.quantidade_inseridos);
        return false; // Exclui do novo array
      }
      return true; // Mantém no novo array
    });

    // Adiciona a nova área operacional "Não definido" com a soma das quantidades
    if (somaNaoDefinida > 0) {
      dadosFiltrados.push({
        area_operacional: "NÃO DEFINIDO",
        quantidade_inseridos: somaNaoDefinida.toString(),
      });
    }

    return dadosFiltrados;
  };

  const loadEstatisticas = () => {
    api.get(
      {
        path: "/api/resources/estatisticas",
      },
      (data) => {
        estatisticas.value = criarAreaNaoDefinida(data);
      },
      (errors) => {},
    );
  };

  const loadEstado = () => {
    api.get(
      {
        path: "/api/resources/estado",
      },
      (data) => {
        setEstado(data);
      },
    );
  };

  const loadSituacao = () => {
    api.get(
      {
        path: "/api/resources/situacao",
      },
      (data) => {
        setSituacao(data);
      },
    );
  };

  const loadDesempenhoBrigadistas = () => {
    api.get(
      {
        path: "/api/resources/desempenhos-brigadistas",
      },
      (data) => {
        setBrigadistasDese(data);
      },
    );
  };

  return {
    estado,
    situacao,
    estatisticas,
    brigadistaDese,
  };
};

export default HomeViewModel;
