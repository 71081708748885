interface TitleGraphProps {
  title: string;
  description: string;
  value?: number;
}

const TitleGraph = ({ title, description, value }: TitleGraphProps) => {
  return (
    <div className="flex flex-row justify-between flex-wrap items-center gap-[10px]">
      <div>
        <p className=" text-[16px] font-bold">{title}</p>
        <p className=" text-[14px]">{description}</p>
      </div>
      <p className=" text-[14px]">
        {value !== undefined && (
          <p>
            <span className=" text-[14px] font-semibold">Total Clientes</span>:{" "}
            <span>{value}</span>
          </p>
        )}
      </p>
    </div>
  );
};

export default TitleGraph;
