import { useEffect } from "react";
import api from "../../api";
import { useBetterState } from "../../hooks/use-better-state";
import tasks from "../../utils/tasks";

interface LogsViewProps {
  id: number;
}

interface Log {
  entidade: string;
  action: string;
  data: string;
  author: string;
  email: string;
  action_data: string;
}

const LogsView = ({ id }: LogsViewProps) => {
  const logs = useBetterState<Log[] | undefined>([]);

  useEffect(() => {
    loadLogs();
  }, []);

  const loadLogs = () => {
    api.get(
      {
        path: "/api/resources/logs",
        params: {
          id: id,
        },
      },
      (data) => {
        const newData = data;
        logs.value = newData;
      },
      (errors) => {},
    );
  };

  return (
    <div className="px-[80px]">
      <p className=" font-bold text-[16px]">Relátorio de Ações</p>
      {logs?.value?.map((item) => {
        return (
          <p>{`Nome : ${item.author} | Email : ${item.email} | Data : ${tasks.convertToGMTPlus2(item.data)} | Ação : ${item.action}`}</p>
        );
      })}
    </div>
  );
};

export default LogsView;
