import { Button } from "@mui/material";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../api";
import ImageView from "../../components/client/image";
import LogsView from "../../components/client/logs";
import LabelInfo from "../../components/label-info";
import { EntrevistadoTipo } from "../../constants/cliente-form";
import { Client } from "../../types/client";
import tasks from "../../utils/tasks";

const VerPotencial = () => {
  const { id } = useParams();
  const [client, setClient] = useState<Client>();

  useEffect(() => {
    loadCliente(id);
  }, []);

  const loadCliente = (id: any) => {
    api.get(
      {
        path: `/api/cliente/info?id=${id}`,
      },
      (data) => {
        setClient(data);
      },
      (errors) => {
        console.log(errors);
      },
    );
  };

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px] pb-[40px]">
        <div className="flex flex-row px-[100px] py-[20px] w-full justify-between">
          <span className=" text-[20px] font-bold text-center tablet:text-left">
            Informação do Entrevistado (Outros)
          </span>
          <Link to={`/gerais/actualizar-cliente/${id}`}>
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: "#4F8F56" }}
            >
              ACTUALIZAR
            </Button>
          </Link>
        </div>
        <div className="flex flex-col gap-[15px] px-[30px] tablet:flex-row tablet:px-[80px] tablet:gap-[50px]">
          <div className="flex flex-1 gap-[15px] flex-col ">
            <span className=" text-[14px] font-bold text-blue-900">
              Informações Pessoais
            </span>
            <LabelInfo
              title="Nome Completo"
              value={tasks.verificarValor(client?.nome, "Sem informação")}
            />
            <LabelInfo
              title="Email"
              value={tasks.verificarValor(client?.email, "Sem informação")}
            />
            <LabelInfo
              title="Numero de telefone 1"
              value={tasks.verificarValor(client?.contacto1, "Sem informação")}
            />
            <LabelInfo
              title="Numero de telefone 2"
              value={tasks.verificarValor(client?.contacto2, "Sem informação")}
            />
            <LabelInfo
              title="Numero de telefone 3"
              value={tasks.verificarValor(client?.contacto3, "Sem informação")}
            />
            <LabelInfo
              title="Numero de telefone 4"
              value={tasks.verificarValor(client?.contacto4, "Sem informação")}
            />
            <LabelInfo
              title="Tipo de actividade"
              value={tasks.verificarValor(
                client?.tipo_actividade,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Qual é a fonte de água?"
              value={tasks.verificarValor(client?.fonte_agua, "Sem informação")}
            />
            {(client?.fonte_agua === "Outras" ||
              client?.fonte_agua === "Fornecimento Privado") && (
              <LabelInfo
                title="Especificação(Fonte de Água)"
                value={tasks.verificarValor(
                  client?.fonte_agua_desc,
                  "Sem informação",
                )}
              />
            )}
            {client?.tipo_registo === EntrevistadoTipo.PRIVADO ? (
              <>
                <LabelInfo
                  title="Porque não tem a ligação da água da ADRMM?"
                  value={tasks.verificarValor(
                    client?.sem_adrmm_porque,
                    "Sem informação",
                  )}
                />
                {client.sem_adrmm_porque === "Estou a arrendar" && (
                  <>
                    <LabelInfo
                      title="Nome do Senhório"
                      value={tasks.verificarValor(
                        client?.senhorio_nome,
                        "Sem informação",
                      )}
                    />
                    <LabelInfo
                      title="Senhório Contacto 1"
                      value={tasks.verificarValor(
                        client?.senhorio_contacto1,
                        "Sem informação",
                      )}
                    />
                    <LabelInfo
                      title="Senhório Contacto 2"
                      value={tasks.verificarValor(
                        client?.contacto2,
                        "Sem informação",
                      )}
                    />
                  </>
                )}
                <LabelInfo
                  title="Está interessado em ter a água da ADRMM?"
                  value={tasks.verificarValor(
                    client?.interessado_na_adrmm,
                    "Sem informação",
                  )}
                />
              </>
            ) : (
              <LabelInfo
                title="Tem suspeita de consumo ilegal?"
                value={tasks.verificarValor(
                  client?.suspeita_ilegal,
                  "Sem informação",
                )}
              />
            )}
          </div>
          <div className="flex flex-1 gap-[15px] flex-col min-h-[400px]">
            <div className="w-full flex flex-1 flex-col h-[400px]">
              <div className="py-[20px]">
                <span className=" text-[14px] font-bold text-blue-900">
                  Geolocalização
                </span>
              </div>
              <APIProvider
                apiKey={"AIzaSyB5v8QIe5AcXYGeGwZuGUcnZh_21ZyBHxM"}
                onLoad={() => console.log("Maps API has loaded.")}
              >
                <Map
                  className="h-[400px] tablet:h-full"
                  defaultZoom={14}
                  defaultCenter={{
                    lat: Number(client?.latitude),
                    lng: Number(client?.longitude),
                  }}
                >
                  <Marker
                    position={{
                      lat: Number(client?.latitude),
                      lng: Number(client?.longitude),
                    }}
                  />
                </Map>
              </APIProvider>
              <div className="w-full flex flex-row border-[2px] mt-[15px] border-black-300 px-[6px] items-center rounded-[8px] py-[5px]">
                <div className="flex flex-1 justify-evenly mr-[20px]">
                  <span className=" font-bold text-[15px]">
                    Lat :{" "}
                    <span className=" font-normal">{client?.latitude}</span>{" "}
                  </span>
                  |
                  <span className=" font-bold">
                    Lng :{" "}
                    <span className=" font-normal">{client?.longitude}</span>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!(
          client?.suspeita_ilegal === "Não" &&
          (client?.tipo_registo === EntrevistadoTipo.POTENCIAL ||
            client.tipo_registo === EntrevistadoTipo.PRIVADO)
        ) && (
          <div className="w-full  py-[20px] px-[30px] tablet:px-[80px]">
            <div className=" my-[20px] w-full">
              <p className=" text-[14px] font-bold text-blue-900 text-center">
                Fotos
              </p>
            </div>
            <div className="grid grid-cols-1 gap-[15px] md:grid-cols-3">
              <div className="flex flex-1">
                <ImageView name={client?.foto_contador} />
              </div>
              <div>
                <ImageView name={client?.foto_residencia} />
              </div>
              <div>
                <ImageView name={client?.foto_balisa} />
              </div>
            </div>
          </div>
        )}
        <div className="w-full  py-[20px] px-[30px] tablet:px-[80px]">
          <LabelInfo
            title="Observações"
            value={tasks.verificarValor(client?.observacao, "Sem informação")}
          />
        </div>
        <div>
          <LogsView id={Number(id)} />
        </div>
        {/* <div className="w-full  py-[20px] px-[30px] tablet:px-[80px]">
          <Link to={""}>
            <CosButton fullWidth>ACTUALIZAR</CosButton>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default VerPotencial;
