import { useEffect, useState } from "react";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import { Constants } from "../../constants/home-constants";
import { useBetterState } from "../../hooks/use-better-state";
import useViewLog from "../../hooks/use-view-log";
import { Client } from "../../types/client";
import { UserType } from "../../types/usuario";
import { DataPagened } from "../../types/utils";
import useAppInfo from "../../zustand/app-info-zus";

interface ClientProps {
  type: "LOADING" | "DATA" | "NONE";
  client: any;
}

const ClientViewModel = () => {
  const [isUpdate, setIsUpdate] = useState(false);
  const { viewLog, setNewLog } = useViewLog();
  const isLoading = useBetterState(false);

  const { user } = useAppInfo();

  const handleSubmitForm = (
    formValues: any,
    f1: any,
    f2: any,
    fotobaliza: any,
    listenerSucess?: () => void,
  ) => {
    let situacao = "";
    if (formValues.numero_contador === null) {
      situacao = Constants.situacaoCadastro.irregular;
    } else if (
      formValues.tem_recebido_factura === null ||
      formValues.estado_do_contador === null ||
      formValues.leitura_actual === null ||
      formValues.diametro_contador === null
    ) {
      situacao = Constants.situacaoCadastro.incompleto;
    } else {
      situacao = Constants.situacaoCadastro.correcto;
    }

    const formH = new FormData();

    formH.append("id", isUpdate ? formValues?.id : "none");
    formH.append("nome", formValues.nome);
    formH.append("email", formValues.email);
    formH.append("contacto1", formValues.contacto1);
    formH.append("contacto2", formValues.contacto2);
    formH.append("contacto3", formValues.contacto3);
    formH.append("contacto4", formValues.contacto4);
    formH.append("area_operacional", formValues.area_operacional);
    formH.append("bairro", formValues.bairro);
    formH.append("avenida_rua", formValues.avenida_rua);
    formH.append("n_casa_rua", formValues.n_casa_rua);
    formH.append("flat", formValues.flat);
    formH.append("andar", formValues.andar);
    formH.append("numero_de_agregado", formValues.numero_de_agregado);
    formH.append("casa_propria", formValues.casa_propria);
    formH.append("tem_recebido_factura", formValues.tem_recebido_factura);
    formH.append("agua_sai_regularidade", formValues.agua_sai_regularidade);
    formH.append(
      "agua_sai_regularidade_desc",
      formValues.agua_sai_regularidade_desc,
    );
    formH.append("estado_cavalete", formValues.estado_cavalete);
    formH.append("cavalete_tem_fugas", formValues.cavalete_tem_fugas);
    formH.append("estado_do_contador", formValues.estado_do_contador);
    formH.append("diametro_contador", formValues.diametro_contador);
    formH.append("regularidade_leitura", formValues.regularidade_leitura);
    formH.append("avaliacao_adrmm", formValues.avaliacao_adrmm);
    formH.append("numero_contador", formValues.numero_contador);
    formH.append("leitura_actual", formValues.leitura_actual);
    formH.append("data_registro", formValues.data_registro);
    formH.append("situacao_entrevistado", situacao);
    formH.append("tipo_actividade", formValues.tipo_actividade);
    formH.append("fonte_agua", formValues.fonte_agua);
    formH.append("fonte_agua_desc", formValues.fonte_agua_desc);
    formH.append("suspeita_ilegal", formValues.suspeita_ilegal);

    formH.append("latitude", formValues.latitude);
    formH.append("longitude", formValues.longitude);
    formH.append("observacao", formValues.observacao);
    formH.append("sem_adrmm_porque", formValues.sem_adrmm_porque);
    formH.append("senhorio_nome", formValues.senhorio_nome);
    formH.append("senhorio_contacto1", formValues.senhorio_contacto1);
    formH.append("senhorio_contacto2", formValues.senhorio_contacto2);
    formH.append("interessado_na_adrmm", formValues.interessado_na_adrmm);
    formH.append("tipo_registo", formValues.tipo_registo);
    formH.append("id_registrador", String(user?.id));
    formH.append("parceiro_negocio", formValues.parceiro_negocio);
    formH.append("conta_contrato", formValues.conta_contrato);
    formH.append("instalacao", formValues.instalacao);
    formH.append("unidade_leitura", formValues.unidade_leitura);
    formH.append("zona", formValues.zona);
    formH.append("rua", formValues.rua);
    formH.append("tipo_instalacao", formValues.tipo_instalacao);

    formH.append("foto_contador", f1);
    formH.append("foto_residencia", f2);
    formH.append("foto_balisa", fotobaliza);

    isLoading.value = true;
    api.post(
      {
        path: "/api/cliente",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formH,
        params: {
          nome: user?.nome,
          email: user?.email,
        },
      },
      (data: any) => {
        listenerSucess?.();
        isLoading.value = false;
        setNewLog({
          type: "SUCCESS",
          message: "Entrevistado registado com sucesso.",
        });
      },
      (error) => {
        console.log(error);
        isLoading.value = false;
        if (error.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão de internet.",
          });
        } else {
          setNewLog({
            type: "WARNING",
            message: "Erro inesperado, se persistir contacte o administrador.",
          });
        }
      },
    );
  };

  const [clientes, setClientes] = useState<DataPagened<Client>>({
    data: [],
    pagination: {
      currentPage: 1,
      totalPages: 1,
    },
  });

  useEffect(() => {
    loadClientes(1);
  }, []);

  const loadClientes = (page: number) => {
    api.get(
      {
        ...(user?.role !== UserType.Brigadista
          ? {
              path: "/api/cliente",
              params: {
                page: page,
                type: "BRI",
              },
            }
          : {
              path: "/api/cliente/b-clientes",
              params: {
                page: page,
                email: user?.email,
              },
            }),
      },
      (data) => {
        // const
        const datatyped = data;
        setClientes(datatyped);
      },
      (errors) => {
        setClientes({
          ...clientes,
          info: {
            status: "SUCCESS",
            message: "Erro de rede tente novamente.",
          },
        });
      },
    );
  };

  const listenerChangeNC = (nc: string) => {};

  return {
    viewLog,
    clientes,
    isLoading,
    setNewLog,
    setClientes,
    loadClientes,
    setIsUpdate,
    listenerChangeNC,
    handleSubmitForm,
  };
};

export default ClientViewModel;
