const Constants = {
  estadoContador: {
    bom: "Bom",
    semContador: "Sem Contador",
    leituraInv: "Leitura Não Visível",
    avariado: "Avariado",
    semInfo: "Sem Informação",
  },
  areasOperacionais: [
    {
      name: "Boane",
      bairros: ["VILA DE BOANE"],
    },
    {
      name: "Chamanculo",
      bairros: [
        "MALANGA",
        "CHAMANCULO B",
        "ALTO MAÉ A",
        "ALTO MAÉ B",
        "CHAMANCULO D",
        "CHAMANCULO A",
        "AEROPORTO B",
        "25 DE JUNHO A",
        "XIPAMANINE",
        "UNIDADE 7",
        "AEROPORTO A",
        "BAGAMOYO",
        "LUIS CABRAL",
        "JARDIM",
        "GEORGE DIMITROV",
        "MINKADJUINE",
        "INHAGOIA B",
        "MUNHUANA",
        "CHAMANCULO C",
        "25 DE JUNHO B",
        "INHAGOIA A",
        "NSALENE",
      ],
    },
    {
      name: "Katembe",
      bairros: ["GUACHENE", "KATEMBE", "CHALI", "CHAMISSAVA"],
    },
    {
      name: "Laulane",
      bairros: [
        "POLANA CANIÇO B",
        "ZIMPETO",
        "COSTA DO SOL",
        "MAXAQUENE D",
        "MAVALANE A",
        "MAHOTAS",
        "HULENE B",
        "MAVALANE B",
        "ALBASINE",
        "POLANA CANIÇO A",
        "LAULANE",
        "FPLM",
        "HULENE A",
        "FERROVIÁRIO",
        "3 DE FEVEREIRO",
        "MALHAZINE",
        "ZINTAVA",
        "CHIANGO",
        "GUAVA",
        "MAGOANINE C",
        "CUMBEZA",
        "MAGOANINE A",
        "15 DE AGOSTO",
      ],
    },
    {
      name: "Machava",
      bairros: [
        "ACORDOS DE LUSAKA",
        "LIBERDADE",
        "PATRICE LUMUMBA",
        "MACHAVA SEDE",
        "BUNHIÇA",
        "VALE DO INFULENE",
        "UNIDADE A",
        "ZONA T3",
        "FOMENTO-MACHAVA",
        "ZONA VERDE",
        "UNIDADE D",
        "SINGATELA",
        "CIKWAMA",
        "S.DÂMASO",
        "NDLHAVELA",
      ],
    },
    {
      name: "Matola",
      bairros: [
        "MATOLA A",
        "FOMENTO",
        "MATOLA C",
        "MATOLA G",
        "MATOLA D",
        "TREVO",
        "MATOLA J",
        "MATOLA F",
        "MATOLA B",
        "MATOLA H",
      ],
    },
    {
      name: "Matola-Rio",
      bairros: [
        "MATOLA-RIO",
        "CHINONAQUILA",
        "CAMPOANE",
        "BELELUANE",
        "BELO HORIZONTE",
        "DJUBA",
        "DJONASSE",
      ],
    },
    {
      name: "Maxaquene",
      bairros: [
        "POLANA CIMENTO B",
        "CENTRAL B",
        "SOMMERSCHIELD",
        "MALHANGALENE B",
        "POLANA CIMENTO A",
        "CENTRAL C",
        "COOP",
        "URBANIZAÇÃO",
        "MALHANGALENE A",
        "MAFALALA",
        "MAXAQUENE C",
        "CENTRAL A",
        "MAXAQUENE B",
        "MAXAQUENE A",
      ],
    },
    {
      name: "Tsalala",
      bairros: [
        "TCHUMENE 1",
        "INTAKA",
        "MALHAMPSENE",
        "CIKWAMA (TSALALA)",
        "TSALALA",
        "MULOTANA",
        "MUSSUMBULUCO (TSALALA)",
        "MATOLA-GARE",
        "MATLEMELE",
        "NKOBE",
        "PRIMEIRO DE MAIO",
        "SIDUAVA",
        "KONGOLOTE",
        "BAIRRO DE MOAMBA",
        "NDLAVELA (TSALALA)",
        "BOQUIÇO",
        "MATIBWANA",
        "MAPANDANE",
      ],
    },
  ],
  situacaoCadastro: {
    correcto: "Correcto",
    irregular: "Irregular",
    incompleto: "Incompleto",
    todas: "Todas Situações",
  },
};

export { Constants };
