import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Link } from "react-router-dom";
import { EntrevistadoTipo } from "../../constants/cliente-form";
import { useBetterState } from "../../hooks/use-better-state";
import { ClientFormProps } from "./client-form";
interface DropdownValues {
  temAgua: string;
  clienteAdr: string;
}

interface AvaliationFormProps extends ClientFormProps {
  renderCliente?: (dropdownsState: DropdownValues) => React.ReactNode;
  renderPotencial?: (dropdownsState: DropdownValues) => React.ReactNode;
  renderNaoCliente?: (dropdownsState: DropdownValues) => React.ReactNode;
}

const AvaliationForm = ({
  renderCliente,
  renderNaoCliente,
  renderPotencial,
  setFieldValue,
  values,
  errors,
}: AvaliationFormProps) => {
  const dropdowns = useBetterState<DropdownValues>({
    temAgua: "",
    clienteAdr: "",
  });

  return (
    <div className="flex flex-1 flex-col w-full gap-[15px]">
      <div className="flex flex-row items-center justify-between">
        <p className=" text-[18px] font-bold text-center">Dados Gerais</p>
        <Link to={"/geral/nav/lista-clientes"}>
          <Button variant="contained">Lista Clientes</Button>
        </Link>
      </div>
      <FormControl size="small" fullWidth variant="outlined">
        <InputLabel id="acesso_agua">Tem ligação de água? *</InputLabel>
        <Select
          required
          labelId="acesso_agua"
          value={dropdowns.value?.temAgua}
          label="Tem acesso á água? *"
          onChange={(event) => {
            dropdowns.value = {
              clienteAdr: "",
              temAgua: event.target.value,
            } as DropdownValues;

            if (event.target.value === "Não") {
              setFieldValue?.("tipo_registo", EntrevistadoTipo.POTENCIAL);
            }
          }}
        >
          <MenuItem value={"Sim"}>Sim</MenuItem>
          <MenuItem value={"Não"}>Não</MenuItem>
        </Select>
      </FormControl>
      {dropdowns.value?.temAgua == "Sim" ? (
        <>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel id="cliente_adrmm">É cliente da ADRMM? *</InputLabel>
            <Select
              labelId="cliente_adrmm"
              value={dropdowns.value?.clienteAdr}
              label="É cliente da ADRMM?"
              onChange={(event) => {
                dropdowns.value = {
                  ...dropdowns.value,
                  clienteAdr: event.target.value,
                } as DropdownValues;
                if (event.target.value === "Sim") {
                  setFieldValue?.("tipo_registo", EntrevistadoTipo.CLIENTE);
                } else {
                  setFieldValue?.("tipo_registo", EntrevistadoTipo.POTENCIAL);
                }
              }}
            >
              <MenuItem value={"Sim"}>Sim</MenuItem>
              <MenuItem value={"Não"}>Não</MenuItem>
            </Select>
          </FormControl>
        </>
      ) : (
        dropdowns.value?.temAgua == "Não" &&
        renderPotencial?.(dropdowns.value as DropdownValues)
      )}
      {dropdowns.value.temAgua === "Sim" &&
        dropdowns.value.clienteAdr === "Sim" &&
        renderCliente?.(dropdowns.value as DropdownValues)}
      {dropdowns.value.temAgua === "Sim" &&
        dropdowns.value.clienteAdr === "Não" &&
        renderNaoCliente?.(dropdowns.value as DropdownValues)}
    </div>
  );
};

export default AvaliationForm;
